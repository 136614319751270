import axios from "axios";

export const getProperties = (query: any) => {
	return new Promise<any>(async (res, rej) => {
		try {
			const { data } = await axios.get("search-engine", {
				params: {
					query: Buffer.from(JSON.stringify(query)).toString("base64"),
				},
			});

			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getSingleProperty = (id: any) => {
	return new Promise<any>(async (res, rej) => {
		try {
			const { data } = await axios.get(`search-engine/${id}`);
			res(data.doc);
		} catch (error) {
			rej(error);
		}
	});
};

export const getGeneralProperties = () => {
	return new Promise<any>(async (res, rej) => {
		try {
			const { data } = await axios.get("general-properties");

			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getPropertiesCount = (query: any) => {
	return new Promise<any>(async (res, rej) => {
		try {
			const { data } = await axios.get("search-engine/count", {
				params: {
					query: Buffer.from(JSON.stringify(query)).toString("base64"),
				},
			});

			res(data?.count);
		} catch (error) {
			rej(error);
		}
	});
};

export const saveSearch = (search: any) => {
	const { name, locale, search_link, ..._search } = search;
	return new Promise<{ link: string; id: number }>(async (res, rej) => {
		try {
			// const base64Link = Buffer.from(
			//           JSON.stringify({
			//               ...search,
			//               search: search?.search.map(({ boundary, ...rest }) => {
			//                   return {
			//                       ...rest,
			//                   };
			//               }),
			//           })
			//       ).toString("base64");
			//       const modifiedSearchLink = search_link.replace('query=""', `query=${base64Link}`);
			const { data } = await axios.post("save-search", {
				search_term: {
					..._search,
					search: search?.search.map(({ boundary, ...rest }) => {
						return {
							...rest,
						};
					}),
				},
				name,
				locale,
				search_link,
			});
			res({
				link: search_link,
				id: data?.id,
			});
		} catch (error) {
			rej(error);
		}
	});
};

export const editSearch = (id: number, editBody: any) => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.put(`save-search/${id}`, editBody);
			res();
		} catch (error) {
			rej(error);
		}
	});
};

export const deleteSearch = (id: number) => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.delete(`save-search/${id}`);
			res();
		} catch (error) {
			rej(error);
		}
	});
};

export const getSavedPropertiesIds = () => {
	return new Promise<any[]>(async (res, rej) => {
		try {
			const { data } = await axios.get("saved-properties/minimal/user");
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getSavedSearches = (page: number, limit: number) => {
	return new Promise<any>(async (res, rej) => {
		try {
			const { data } = await axios.get("save-search/user", {
				params: {
					page,
					limit,
					// sortOrder: "DESC",
				},
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const searchQueryOnMapbox = (query: string, locale: string) => {
	return new Promise<any[]>(async (res, rej) => {
		try {
			const { data } = await axios.get(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=be&types=postcode,place&language=${
					locale === "nl"
						? "nl,en,fr"
						: locale === "fr"
						? "fr,en,nl"
						: "en,nl,fr"
				}
					&access_token=pk.eyJ1IjoibWF0dGVvZ3JhY2VmZmEiLCJhIjoiY2txYjBiZW11MDVwcjJwbm1yMmdlaGY2eSJ9.5LiTaHbs8vlwsjwAMzm1eA`
			);

			res([
				...data.features.map((feature) => {
					const type = feature?.place_type[0];
					// sconsole.log(feature, "feature");
					return {
						id: feature?.id,
						center: feature?.center,
						city_en:
							type === "postcode"
								? feature?.context?.filter(
										(el) => el.id.indexOf("place") !== -1
								  )[0]?.text_en || ""
								: feature?.text_en,
						city_fr:
							type === "postcode"
								? feature?.context?.filter(
										(el) => el.id.indexOf("place") !== -1
								  )[0]?.text_fr || ""
								: feature?.text_fr,
						city_nl:
							type === "postcode"
								? feature?.context?.filter(
										(el) => el.id.indexOf("place") !== -1
								  )[0]?.text_nl || ""
								: feature?.text_nl,
						zip: type === "postcode" ? feature?.text_en : "",
						type,
					};
				}),
			]);
		} catch (error) {
			rej(error);
		}
	});
};

export const contactAgencyByEmail = (body: any) => {
	return new Promise<void>(async (res, rej) => {
		try {
			await axios.post(`general-properties/contact-agency`, body);
			res();
		} catch (error) {
			rej(error);
		}
	});
};

export const getCountOfSaleRentProperties = () => {
	return new Promise<any>(async (res, rej) => {
		try {
			const { data } = await axios.get(`search-engine/home-page/count`);
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const searchAddressAutocomplete = (q: string) => {
	return new Promise<any[]>(async (res, rej) => {
		try {
			const { data } = await axios.get("city-boundary/search", {
				params: {
					keyword: q,
					include_boundary: true,
					limit: 10,
				},
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};

export const getBoundariesByIds = (ids: any[]) => {
	return new Promise<any[]>(async (res, rej) => {
		try {
			const { data } = await axios.post("city-boundary/by-ids", {
				ids,
			});
			res(data);
		} catch (error) {
			rej(error);
		}
	});
};
