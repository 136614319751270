import React from "react";
import logo from "../../assets/images/proppy-header-2.svg";
import { useMediaQuery } from "react-responsive";

const Loading = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
	return (
		<div
			style={{ 
				height: isMobile ? "75vh" : "100vh"
		}}
			className="d-flex align-items-center justify-content-center"
		>
			<img src={logo} alt="logo" width={104} height={26}/>
		</div>
	);
};

export default Loading;
