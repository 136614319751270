import React, { useState } from "react";
import { useTranslation } from "next-i18next";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Switch from "antd/lib/switch";
import Radio from "antd/lib/radio";
import Form from "antd/lib/form";
import { useRouter } from "next/router";
import styled from "styled-components";
import MailIcon from "../../../assets/images/mail-icon.svg";
import PersonIcon from "../../../assets/images/person.svg";
import ErrorIcon from "../../../assets/images/error-icon.svg";
import { homecostFunnel } from "../../../network-requests";
import { useLinkTranslation } from "../../../custom-hooks/useLinkTranslation";

const MainHeading = styled.div`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 27px;
	text-align: center;
	color: #1d2e5b;
	max-width: 85%;
	@media (min-width: 768px) {
		font-size: 28px;
		line-height: 38px;
	}
`;

const ParaghafBelow = styled.div`
	p {
		font-size: 12px;
		line-height: 16px;
		color: #6c768f;
	}
`;

const SubHeading = styled.div`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	color: #1d2e5b;

	width: 500px;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 5px;
	@media (min-width: 768px) {
		font-size: 16px;
	}
	@media (max-width: 500px) {
		font-size: 14px;
		width: 100%;
	}
`;

const BlockContainer = styled.div`
	width: 100%;
	min-height: calc(100vh - var(--headerHeight));
	padding-top: 50px;
	padding-bottom: 50px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: #f9faff;
	@media (min-width: 768px) {
		padding-bottom: 100px;
	}
`;

const SliderContainer = styled.div`
	width: 100%;
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	@media (min-width: 768px) and (max-width: 1439px) {
		width: 450px;
	}
	@media (min-width: 1440px) {
		width: 550px;
	}
`;

const QuestionHeading = styled.div`
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 38px;
	color: #1d2e5b;
	text-align: center;
	@media (max-width: 500px) {
		width: 100%;
	}
`;

const RadioDiv = styled.div`
	@media (min-width: 501px) {
		width: 100%;
	}
	@media (max-width: 500px) {
		width: 100%;
	}
`;

const AdditionalQuestionMainBlock = styled.div`
	span.ant-radio + * {
		padding-right: 8px;
		padding-left: 8px;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		color: #1d2e5b;
	}

	p {
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		color: #1d2e5b;
		margin-top: 10px;
	}
`;

const NextButton = styled.div`
	Button {
		@media (max-width: 500px) {
			width: 100%;
		}
	}
`;

const StyledForm = styled(Form)`
	.custom-form-item {
		margin-bottom: 10px;
	}
`;

export function formatToCurrency(amount) {
	try {
		const x = new Intl.NumberFormat("en-BE", {
			style: "currency",
			currency: "EUR",
			maximumFractionDigits: 0,
			// minimumIntegerDigits: 0,
		}).format(parseInt(amount));
		return `€${x.replace("€", "").replace(/,/g, ".")}`;
	} catch (error) {
		console.log(error);
	}
}

export function formatToCurrencyForPrice(amount) {
	try {
		if (amount > 950000) {
			const millions = amount / 1000000;
			if (millions >= 1 && millions <= 6) {
				// Adjust the value to the nearest 0.25M
				const formattedMillions =
					Math.floor(millions) + Math.round((millions % 1) * 100) / 100;
				return `€${formattedMillions}M`;
			} else {
				return `€${Math.floor(millions)}M`;
			}
		} else {
			const formattedAmount = new Intl.NumberFormat("en-BE", {
				style: "currency",
				currency: "EUR",
				maximumFractionDigits: 0,
			}).format(parseInt(amount));
			return `€${formattedAmount.replace("€", "").replace(/,/g, ".")}`;
		}
	} catch (error) {
		console.log(amount);
		console.log(error);
	}
}

export function formatToCurrencyForCount(amount) {
	try {
		const x = new Intl.NumberFormat("en-BE", {
			style: "currency",
			currency: "EUR",
			// maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		}).format(parseInt(amount));
		return `${x.replace("€", "").replace(/,/g, ".")}`;
	} catch (error) {
		console.log(amount);
		console.log(error);
	}
}

const SellingHouse = ({ goToNextStep }) => {
	const [homeSalePrice, setHomeSalePrice] = useState(null);
	const [agentFees, setAgentFees] = useState("2.5");
	const { t } = useTranslation("homecost-funnel");
	const [form] = Form.useForm();

	return (
		<div
			style={{ width: "100%" }}
			className="d-flex flex-column align-items-center"
		>
			<MainHeading>{t("h3.selling-house-main-heading")}</MainHeading>
			<SubHeading>{`${t("h3.selling-house-sub-heading ")}`}</SubHeading>

			<div
				className="col-12 col-md-10 col-lg-5 col-xl-4"
				style={{ marginTop: 30 }}
			>
				<StyledForm
					onFinish={(values: any) => {
						goToNextStep({
							...values,
						});
					}}
					form={form}
					layout="vertical"
					requiredMark={false}
					initialValues={{
						home_sale_price: homeSalePrice,
						agent_fees: agentFees,
						repair_cost: null,
					}}
				>
					<Form.Item
						className="custom-form-item"
						requiredMark={true}
						label={
							<div
								style={{
									color: "##1D2E5B",
									marginBottom: -10,
									fontSize: "14px",
								}}
							>
								{t("label.home-sale-price")}
							</div>
						}
						name="home_sale_price"
						rules={[
							{
								required: true,
								message: (
									<div className="d-flex align-items-center">
										<img
											src={ErrorIcon}
											alt="ErrorIcon"
											style={{
												marginRight: "6px",
												height: "18px",
												marginTop: "-4px",
											}}
										/>
										{t("error.required")}
									</div>
								),
							},
						]}
					>
						<Input
							type="number"
							min={0}
							onChange={(e) => {
								form.setFieldsValue({
									home_sale_price: e.target.value,
								});
								setHomeSalePrice(e.target.value);
							}}
							data-cy="home_sale"
							prefix={<div style={{ marginRight: 5 }}>€</div>}
							style={{
								// minWidth: 350,
								borderRadius: 8,
								color: "#1D2E5B",
								fontSize: "16px",
								height: 50,
							}}
							allowClear
						/>
					</Form.Item>
					<ParaghafBelow>
						<p> {t("label.home-sale-price")}</p>
					</ParaghafBelow>
					<Form.Item
						className="custom-form-item"
						label={
							<div
								style={{
									color: "##1D2E5B",
									marginBottom: -10,
									fontSize: "14px",
								}}
							>
								{t("label.real-estate-fee")}
							</div>
						}
						name="agent_fees"
						rules={[
							{
								required: true,
								message: (
									<div className="d-flex align-items-center">
										<img
											src={ErrorIcon}
											alt="ErrorIcon"
											style={{
												marginRight: "6px",
												height: "18px",
												marginTop: "-4px",
											}}
										/>
										{t("error.required")}
									</div>
								),
							},
						]}
					>
						<Input
							type="number"
							onChange={(e) => setAgentFees(e.target.value)}
							data-cy="agent_fees"
							prefix={<div style={{ marginRight: 10 }}>%</div>}
							suffix={
								<div
									className="d-flex align-items-center justify-content-center"
									style={{
										background: "#F2F6FF",
										color: "#1D2E5B",
										height: "120%",
										padding: "0px 20px",
										marginRight: -11,
										borderRadius: "0px 8px 8px 0px",
									}}
								>
									{formatToCurrency(
										homeSalePrice *
											(parseFloat(agentFees ? agentFees : "0") / 100)
									)}
								</div>
							}
							style={{
								borderRadius: 8,
								color: "#1D2E5B",
								fontSize: "16px",
								height: 50,
							}}
							allowClear
						/>
					</Form.Item>
					<ParaghafBelow>
						<p>{t("p.agent-fee-desc")}</p>
					</ParaghafBelow>
					<Form.Item
						className="custom-form-item"
						label={
							<div
								style={{
									color: "##1D2E5B",
									marginBottom: -10,
									fontSize: "14px",
								}}
							>
								{t("label.repair-cost")}
							</div>
						}
						name="repair_cost"
						rules={[
							{
								required: true,
								message: (
									<div className="d-flex align-items-center">
										<img
											src={ErrorIcon}
											alt="ErrorIcon"
											style={{
												marginRight: "6px",
												height: "18px",
												marginTop: "-4px",
											}}
										/>
										{t("error.required")}
									</div>
								),
							},
						]}
					>
						<Input
							type="number"
							min={0}
							prefix={<div style={{ marginRight: 5 }}>€</div>}
							data-cy="repair_cost"
							style={{
								// minWidth: 350,
								borderRadius: 8,
								color: "#1D2E5B",
								fontSize: "16px",
								height: 50,
							}}
							allowClear
						/>
					</Form.Item>
					<ParaghafBelow>
						<p>{t("p.repair-cost-desc")}</p>
					</ParaghafBelow>
					<div style={{ marginTop: 40 }}>
						<Button
							style={{
								borderRadius: 8,
								height: 50,
								color: "#FFFFFF",
								fontWeight: "bold",
								backgroundColor: "#3871EF",
								fontSize: "16px",
							}}
							data-cy="submit_home"
							size="large"
							block
							htmlType="submit"
						>
							{t("btn.next")}
						</Button>
					</div>
				</StyledForm>
			</div>
		</div>
	);
};

const AdditionalAnswersPageTwo = ({ goToNextStep }) => {
	const [isHouseBoughtIn5Years, setIsHouseBoughtIn5Years] = useState(false);
	const [isMortgageLoan, setIsMortgageLoan] = useState(false);
	const [isFuelTank, setIsFuelTank] = useState(false);
	const [mortgageLoan, setMortgageLoan] = useState("transfer-loan");

	const { t } = useTranslation("homecost-funnel");
	const [form] = Form.useForm();

	return (
		<div
			style={{ width: "100%" }}
			className="d-flex flex-column align-items-center"
		>
			<QuestionHeading>{t("heading.additional-question")}</QuestionHeading>
			<SubHeading>{t("heading.additional-sub-heading")}</SubHeading>
			<AdditionalQuestionMainBlock
				className="col-12 col-md-10 col-lg-5 col-xl-4"
				style={{ marginTop: 20 }}
			>
				<div
					className="d-flex w-100 flex-column justify-content-between"
					style={{
						background: "#FFFFFF",
						borderRadius: 8,
						fontSize: 15,
						fontWeight: "bold",
						border: "1px solid #E5EAF7",
						color: "var(--mainColor)",
						padding: "20px 12px",
						// paddingBottom: 0,
					}}
				>
					<div className="d-flex flex-row w-100 align-items-start justify-content-between">
						{t("p.bought-house")}
						<Switch
							checked={isHouseBoughtIn5Years}
							onChange={(checked) => setIsHouseBoughtIn5Years(checked)}
							data-cy="bought_house"
						/>
					</div>

					{isHouseBoughtIn5Years && (
						<div>
							<Form
								requiredMark={false}
								onFinish={(values) => {
									goToNextStep({
										isHouseBoughtIn5Years,
										houseBoughtIn5YearsPrice: values?.houseBoughtIn5YearsPrice,
										mortgageLoan,
										isMortgageLoan,
										isFuelTank,
									});
								}}
								style={{ marginTop: 10 }}
								layout="vertical"
								form={form}
								initialValues={{ houseBoughtIn5YearsPrice: "" }}
							>
								<Form.Item
									rules={[{ required: true, message: t("error.required") }]}
									style={{ marginBottom: 0 }}
									name="houseBoughtIn5YearsPrice"
									label={
										<div
											style={{
												color: "var(--mainColor)",
												fontWeight: "normal",
											}}
										>
											{t("p.what-price")}
										</div>
									}
								>
									<Input
										type="number"
										prefix={<div style={{ marginRight: 5 }}>€</div>}
										data-cy="price"
										style={{
											// minWidth: 350,
											height: 42,
											borderRadius: 8,
											color: "#1D2E5B",
											fontSize: "16px",
										}}
									/>
								</Form.Item>
							</Form>
						</div>
					)}
				</div>

				<div
					className="d-flex w-100 flex-column justify-content-between"
					style={{
						background: "#FFFFFF",
						borderRadius: 8,
						fontSize: 15,
						fontWeight: "bold",
						border: "1px solid #E5EAF7",
						color: "var(--mainColor)",
						padding: "20px 12px",
						marginTop: 20,
					}}
				>
					<div className="d-flex flex-row w-100 align-items-start justify-content-between">
						{t("mortgage.loan")}

						<Switch checked={isMortgageLoan} onChange={setIsMortgageLoan} />
					</div>

					{isMortgageLoan && (
						<div className="mt-3">
							<Radio.Group
								onChange={(e) => setMortgageLoan(e.target.value)}
								value={mortgageLoan}
							>
								<div className="d-flex flex-column">
									<Radio style={{ marginBottom: 5 }} value={"transfer-loan"}>
										{t("p.transfer-loan")}
									</Radio>
									<Radio value={"repay-loan"}>{t("p.reply-loan")}</Radio>
								</div>
							</Radio.Group>
						</div>
					)}
				</div>
				<RadioDiv
					className="d-flex justify-content-between align-items-start"
					style={{
						// width: "540px",
						height: "70px",
						padding: 12,
						background: "#FFFFFF",
						borderRadius: 8,
						fontSize: 15,
						fontWeight: "bold",
						marginTop: "20px",
						fontFamily: "Nunito Sans",
						border: "1px solid #E5EAF7",
						color: "#1D2E5B",
					}}
				>
					<div> {t("SubHeading.fuel")}</div>
					<div>
						{" "}
						<Switch checked={isFuelTank} onChange={setIsFuelTank} />
					</div>
				</RadioDiv>
			</AdditionalQuestionMainBlock>
			<NextButton style={{ marginTop: 40 }}>
				<Button
					style={{
						borderRadius: 8,
						height: 50,
						width: "350px",
						color: "#FFFFFF",
						fontWeight: "bold",
						fontFamily: "Nunito Sans",
						backgroundColor: "#3871EF",
					}}
					size="small"
					onClick={() => {
						if (isHouseBoughtIn5Years) {
							form.submit();
						} else {
							goToNextStep({
								isHouseBoughtIn5Years,
								houseBoughtIn5YearsPrice: null,
								mortgageLoan,
								isMortgageLoan,
								isFuelTank,
							});
						}
					}}
					data-cy="submit"
				>
					{t("btn.next")}
				</Button>
			</NextButton>
		</div>
	);
};

const HomeCostBlock = ({ goToNextStep, isLoading }) => {
	const { t } = useTranslation("homecost-funnel");
	const [isPhoneFieldVisible, setIsPhoneFieldVisible] = useState(false);

	return (
		<div
			style={{ width: "100%" }}
			className="d-flex flex-column align-items-center"
		>
			<MainHeading style={{ fontSize: "25px" }}>
				{t("heading.home-cost")}
			</MainHeading>
			<div
				className="col-12 col-md-10 col-lg-5 col-xl-4"
				style={{ marginTop: 30 }}
			>
				<Form
					requiredMark={false}
					initialValues={{ name: "", email: "", phone: "" }}
					layout="vertical"
					onFinish={(values) => {
						if (isPhoneFieldVisible) {
							goToNextStep({
								...values,
								phone: `+32${values?.phone}`,
							});
						} else {
							setIsPhoneFieldVisible(true);
						}
					}}
				>
					<Form.Item
						name="name"
						rules={[
							{
								required: true,
								message: t("error.required"),
							},
						]}
						label={
							<div style={{ color: "var(--mainColor)" }}>{t("label.name")}</div>
						}
					>
						<Input
							style={{
								borderRadius: 8,
								height: 45,
							}}
							allowClear
							placeholder={t("placeholder.name")}
							data-cy="name"
							prefix={
								<img
									style={{ marginRight: 10 }}
									src={PersonIcon}
									alt="PersonIcon"
								/>
							}
						/>
					</Form.Item>
					<Form.Item
						name="email"
						rules={[
							{
								required: true,
								message: t("error.required"),
							},
							{
								type: "email",
								message: t("label.invalid-email"),
							},
						]}
						label={
							<div style={{ color: "var(--mainColor)" }}>
								{t("label.email")}
							</div>
						}
					>
						<Input
							style={{
								borderRadius: 8,
								height: 45,
							}}
							allowClear
							placeholder={t("e.g. example@mail.com")}
							prefix={
								<img
									style={{ marginRight: 10 }}
									src={MailIcon}
									alt="MailIcon"
								/>
							}
							data-cy="email"
						/>
					</Form.Item>

					{isPhoneFieldVisible && (
						<Form.Item
							name="phone"
							rules={[
								() => ({
									validator(_, value) {
										const _phone = value;
										if (!_phone) {
											return Promise.reject(t("error.required"));
										}
										const belgainPhoneRegex =
											/^(4(60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/;
										if (belgainPhoneRegex.test(_phone)) {
											return Promise.resolve();
										} else {
											return Promise.reject(new Error(t("error.invalid-num")));
										}
									},
								}),
							]}
							label={
								<div style={{ color: "var(--mainColor)" }}>
									{t("placeholder.enter-phone")}
								</div>
							}
						>
							<Input
								style={{
									borderRadius: 8,
									height: 45,
								}}
								allowClear
								prefix={<div style={{ color: "gray" }}>+32</div>}
								data-cy="phone"
							/>
						</Form.Item>
					)}
					<div style={{ marginTop: 40 }}>
						<Button
							color="var(--mainColor)"
							size="large"
							block
							type="primary"
							style={{
								borderRadius: 8,
								height: 45,
								color: "#FFFFFF",
								fontWeight: "bold",
								fontFamily: "Nunito Sans",
								backgroundColor: "#3871EF",
							}}
							data-cy="submit"
							htmlType="submit"
							loading={isLoading}
							// onClick={goToNextStep}
						>
							{t("btn.continue")}
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
};

const HomeCostFunnelCity = () => {
	const [step, setStep] = useState(0);
	const [fields, setFields] = useState(null);
	const [address] = useState(
		sessionStorage.getItem("homecost-funnel-address") || null
	);
	const [isLoading, setIsLoading] = useState(false);

	const { query, locale, push } = useRouter();
	const { routerPush } = useLinkTranslation();

	const city = query?.city;
	const zip = query?.zip;
	const latlng = sessionStorage.getItem("homecost-funnel-latlng") || "";

	// const location = zip ? `${city}, ${zip}` : city;

	const goToNextStep = (values) => {
		setFields({
			...fields,
			...values,
		});
		setStep(step + 1);
	};

	const sendFunnelData = async ({ name, email, phone }) => {
		try {
			sessionStorage.setItem(
				"homecost-funnel-fields",
				JSON.stringify({ ...fields })
			);
			setIsLoading(true);
			await homecostFunnel(
				{
					...fields,
					name,
					email,
					phone_number: phone,
					latlng,
					...(zip && { zip }),
					address,
					city,
					home_sale_price: parseInt(`${fields?.home_sale_price}`),
					agent_fees: `${fields?.agent_fees}%`,
					repair_cost: parseInt(`${fields?.repair_cost}`),
					is_house_bought_in_5_years: fields?.isHouseBoughtIn5Years,
					...(fields?.isHouseBoughtIn5Years && {
						house_bought_in_5_year_price: parseInt(
							`${fields?.houseBoughtIn5YearsPrice}`
						),
					}),
					...(fields?.isMortgageLoan && {
						mortgage_loan: fields?.mortgageLoan,
					}),
					have_mortgage_loan: fields?.isMortgageLoan,
					have_fuel_tank: fields?.isFuelTank,
				},
				locale
			);
			routerPush(`/cost-calculator/thank-you`, undefined, { locale });
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<React.Fragment>
			<div
				style={{
					width: `${((step + 1) / 4) * 100}%`,
					position: "absolute",
					height: 4,
					backgroundColor: "var(--colorBlue)",
					transition: "0.5s width",
				}}
			/>
			<BlockContainer>
				{step === 0 ? (
					<SellingHouse goToNextStep={goToNextStep} />
				) : step === 1 ? (
					<AdditionalAnswersPageTwo goToNextStep={goToNextStep} />
				) : step === 2 ? (
					<HomeCostBlock goToNextStep={sendFunnelData} isLoading={isLoading} />
				) : null}
			</BlockContainer>
		</React.Fragment>
	);
};

export default HomeCostFunnelCity;
